.jr_body {
  .ant-typography {
    font-style: normal;
    margin-bottom: 0;
  }

  label.ant-checkbox-wrapper {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    /* 123.077% */
    display: flex;
    align-items: center;

    .ant-checkbox {
      input.ant-checkbox-input {
        height: 20px;
        width: 20px;
        background-color: transparent;
      }

      .ant-checkbox-inner {
        transition: none;
        box-shadow: 0px 0px 2px 0px rgba(76, 78, 80, 0.6);
        border: none;
      }

      .ant-checkbox-inner::after {
        transition: none;
        border-bottom: 2px solid #000;
        border-right: 2px solid #000;
        inset-inline-start: 25%;
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          box-shadow: none;
        }
      }

      &.ant-checkbox-checked.ant-checkbox-disabled + span {
        color: black;
      }
    }

    &:hover {
      .ant-checkbox {
        .ant-checkbox-inner {
          transition: none;
          box-shadow: 0px 0px 2px 0px rgba(76, 78, 80, 0.7);
        }

        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            box-shadow: none;
            transition: none;
            background-color: #00f0a0;
          }
        }

        &.ant-checkbox-checked.ant-checkbox-disabled {
          .ant-checkbox-inner {
            background: var(--Grey, #f2f4f5);

            &::after {
              color: rgba(0, 0, 0, 0.45);
            }
          }
        }
      }
    }
  }

  .ant-drawer {
    .ant-drawer-content-wrapper {
      .ant-drawer-content {
        .ant-drawer-close {
          padding: 0;
          margin: 0;
          margin-inline-end: 16px;

          display: flex;
          width: 32px;
          height: 32px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;

          border-radius: 8px;
          border: 1px solid var(--Grey, #f2f4f5);
        }

        .ant-drawer-body {
          padding: 0;
        }
      }
    }
  }

  .jr-modal-info.ant-modal .ant-modal-body {
    padding: 16px;

    .ant-modal-confirm-body {
      .ant-modal-confirm-title {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 51px;
        color: #000000;

        > img {
          margin-right: 18px;
        }
      }

      .ant-modal-confirm-content {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        padding-left: 56px;
        margin-top: 14px;
        max-width: 100%;
      }
    }

    .ant-modal-confirm-btns {
      text-align: center;
      margin-top: 32px;

      .ant-btn {
        width: 160px;
        height: 54px;
        background: #0af1a4;
        border-radius: 2px;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 39px;
        color: #000000;
      }
    }
  }

  .ant-message {
    .ant-message-notice {
      padding: 0;

      .ant-message-notice-content {
        padding: 12px;
        border-radius: 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #7df5cd;
        box-shadow: 0px 6px 24px 0px rgba(0, 210, 140, 0.2);
      }
    }
  }

  .ant-modal .ant-modal-close:hover {
    background-color: transparent;
  }

  #jobs-page-main-content {
    .ant-message {
      margin-top: 16px;
      position: absolute;
    }
  }
}

.jr_body_tour {
  min-width: 1280px;
}
