/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: fit-content;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

// ul,
// li {
//   list-style: none;
//   padding: 0;
//   margin: 0;
// }

// init scrollbar style
// ::-webkit-scrollbar {
//   height: 9px;
//   width: 9px;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: rgba(0, 0, 0, 0.1);
//   -webkit-background-clip: padding-box;
//   background-clip: padding-box;
//   border: 2px solid rgba(0, 0, 0, 0);
//   border-radius: 10px;
//   border-width: 2px;
// }

// ::-webkit-scrollbar-track {
//   background-color: transparent;
//   border-radius: 10px;
// }

// :hover::-webkit-scrollbar-thumb {
//   background-color: rgba(0, 0, 0, 0.16);
// }

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
}
