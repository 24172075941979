@import "reset";
@import "antd";

html,
body {
  background: #f2f4f5;
  min-width: 360px;

  --color-bg-popup: #fff;
  --color-fill-2: rgb(242, 243, 245);
  --color-fill-3: rgb(229, 230, 235);
  --color-text-3: rgb(134, 144, 156);

  --border-radius-medium: 4px;

  --banner-height: 80px;
}

// ul,
// li,
// ol {
//   padding: 0;
//   margin: 0;
// }

// https://jobright-inc.sentry.io/issues/4476543473/?project=4505347610378240&query=level%3Ainfo&referrer=issue-stream&statsPeriod=7d&stream_index=0
// https://community.shopify.com/c/site-speed/fix-best-practices/m-p/2018763
img,
video,
canvas {
  max-width: max-content;
  overflow: hidden;
}

//#__next {
//  max-height: 100vh;
//}

// .layout-avatar.ant-layout {
//   min-height: 480px;
//   box-shadow: 0 2px 20px 0 #0000001a;
//   background: #ffffff;
//   border-radius: 8px;
//   overflow: hidden;

//   .ant-layout-sider {
//     min-height: 100%;
//     background: #ffffff;
//     box-shadow: 0 2px 20px 0 #0000001a;

//     &-children {
//       height: fit-content;

//       .layout-avatar-header {
//         padding: 40px 0;
//         display: flex;
//         flex-flow: column;
//         align-items: center;
//         justify-content: center;
//         gap: 12px;

//         .ant-typography {
//           font-weight: 600;
//           font-size: 16px;
//         }
//       }

//       .ant-menu {
//         border-inline-end: none;

//         &-item {
//           border-radius: 0;
//           width: 240px;
//           height: 48px;
//           margin: 0;
//           opacity: 0.3;

//           font-size: 16px;
//           color: #0a0a0a;
//           font-weight: 600;
//           line-height: 150%;
//         }

//         &-item-selected {
//           background-color: #d7ffee;
//           opacity: 1;

//           &::before {
//             content: " ";
//             width: 4px;
//             height: 100%;
//             background: #00f0a0;
//             position: absolute;
//             left: 0;
//           }
//         }

//         &-title-content {
//         }
//       }
//     }
//   }
// }

@media screen and(max-width: 768px) {
  body::-webkit-scrollbar {
    display: none;
  }
  body::-moz-scrollbar {
    display: none;
  }
  body::-ms-scrollbar {
    display: none;
  }
}
